.glitch-text {
  flex: 1;
  line-height: .75;
  margin: auto;
  color: #1af0dc;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;

  
  
  &::before,
  &::after {
    --top: 0;        
    --left: 0;      
    --v-height: 30%; 
    --f-size: 15;
    --f-unit: 1vmin;
    --f: calc(var(--f-size) * var(--f-unit));
    /* --bg: #181717;  */
    
    --n-tenth: calc(var(--f-size) * .1 * var(--top));
    --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
    --b-cut: calc(var(--t-cut) + var(--v-height));
    
    content: attr(data-text);
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    
    transform: translateX(calc(var(--left) * 100%));
    
    
    filter: drop-shadow(0 0 transparent); 
    
    text-shadow: calc(var(--left) * -3em) 0 .02em lime, 
                  calc(var(--left) * -6em) 0 .02em #ff00e1;
    
    background-color: var(--bg);
    clip-path: polygon(0% var(--t-cut), 100% var(--t-cut), 100% var(--b-cut), 0% var(--b-cut));
  } 
  
  &::before {
    animation: glitch-b 1.7s infinite alternate-reverse;
  }
  &::after {
    animation: glitch-a 3.1s infinite alternate;
  }
}

@keyframes glitch-a {
  10%,30%,50%,70%,90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%; 
  }
  20% {
    --left: .005;
  }
  40% {
    --left: .01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: .03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: .07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: .083;
    --v-height: 30%;
    --top: 1;
  }
}

@keyframes glitch-b {
    10%,30%,50%,70%,90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%; 
    --top: 10;
  }
  20% {
    --left: -.005;
  }
  40% {
    --left: -.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -.083;
    --v-height: 30%;
    --top: 1;
  }
}